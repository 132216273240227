
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    modelValue: Boolean,
    text: String,
    subQuestionLvl: Number
  }
})
export default class YesNoLabel extends Vue {
  modelValue!: boolean
  text = ''

  subQuestionLvl!: number

  emit (val: boolean): void {
    this.$emit('update:modelValue', val)
  }
}
