
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    modelValue: Number,
    text: String,
    options: Array,
    subQuestionLvl: Number
  }
})
export default class Select extends Vue {
  text!: string
  modelValue!: number
  options: Array<string> = []
  subQuestionLvl!: number

  updateValue (event: InputEvent): void {
    this.$emit('update:modelValue', +(event.target as HTMLInputElement).value)
  }
}
