import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center bg-white rounded-lg p-8 border-2 border-gray-300" }
const _hoisted_2 = { class: "space-y-8 divide-y divide-gray-200 sm:space-y-5" }
const _hoisted_3 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_4 = { class: "mt-1 max-w-2xl text-sm text-gray-500" }
const _hoisted_5 = { class: "flex flex-col gap-4 sm:gap-0" }
const _hoisted_6 = { class: "pt-5" }
const _hoisted_7 = { class: "flex justify-end" }
const _hoisted_8 = {
  type: "submit",
  class: "ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioN = _resolveComponent("RadioN")!
  const _component_Select = _resolveComponent("Select")!
  const _component_YesNoLabel = _resolveComponent("YesNoLabel")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "space-y-8 divide-y divide-gray-200",
      onSubmit: _cache[27] || (_cache[27] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.$t('PreferencesNoStudies.Title')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('PreferencesNoStudies.PersonalPatientInfo')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.adhd_sympt_pat'),
              value: _ctx.preferences.adhd_sympt_pat,
              key: _ctx.preferences.adhd_sympt_pat,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[0] || (_cache[0] = ($event: any) => (_ctx.preferences.adhd_sympt_pat = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.CGI'),
              value: _ctx.preferences.CGI,
              key: _ctx.preferences.CGI,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.preferences.CGI = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.drug_use_prevention'),
              value: _ctx.preferences.drug_use_prevention,
              key: _ctx.preferences.drug_use_prevention,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.preferences.drug_use_prevention = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.drug_use'),
              value: _ctx.preferences.drug_use,
              key: _ctx.preferences.drug_use,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.preferences.drug_use = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.accidents'),
              value: _ctx.preferences.accidents,
              key: _ctx.preferences.accidents,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[4] || (_cache[4] = ($event: any) => (_ctx.preferences.accidents = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.academic_achivement'),
              value: _ctx.preferences.academic_achivement,
              key: _ctx.preferences.academic_achivement,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[5] || (_cache[5] = ($event: any) => (_ctx.preferences.academic_achivement = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.QoL'),
              value: _ctx.preferences.QoL,
              key: _ctx.preferences.QoL,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[6] || (_cache[6] = ($event: any) => (_ctx.preferences.QoL = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            _createVNode(_component_Select, {
              text: _ctx.$t('PreferencesNoStudies.unwanted_ae'),
              options: [
                _ctx.$t('PreferencesNoStudies.unwanted_ae_mild'),
                _ctx.$t('PreferencesNoStudies.unwanted_ae_serious')
              ],
              modelValue: _ctx.preferences.unwanted_ae,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.preferences.unwanted_ae) = $event))
            }, null, 8, ["text", "options", "modelValue"]),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.appetite_decreased'),
              value: _ctx.preferences.appetite_decreased,
              key: _ctx.preferences.appetite_decreased,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[8] || (_cache[8] = ($event: any) => (_ctx.preferences.appetite_decreased = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.insomnia'),
              value: _ctx.preferences.insomnia,
              key: _ctx.preferences.insomnia,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[9] || (_cache[9] = ($event: any) => (_ctx.preferences.insomnia = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.somnolence'),
              value: _ctx.preferences.somnolence,
              key: _ctx.preferences.somnolence,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[10] || (_cache[10] = ($event: any) => (_ctx.preferences.somnolence = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.dizziness'),
              value: _ctx.preferences.dizziness,
              key: _ctx.preferences.dizziness,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[11] || (_cache[11] = ($event: any) => (_ctx.preferences.dizziness = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.dry_mouth'),
              value: _ctx.preferences.dry_mouth,
              key: _ctx.preferences.dry_mouth,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[12] || (_cache[12] = ($event: any) => (_ctx.preferences.dry_mouth = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.tic'),
              value: _ctx.preferences.tic,
              key: _ctx.preferences.tic,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[13] || (_cache[13] = ($event: any) => (_ctx.preferences.tic = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.seizures'),
              value: _ctx.preferences.seizures,
              key: _ctx.preferences.seizures,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[14] || (_cache[14] = ($event: any) => (_ctx.preferences.seizures = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.vomiting'),
              value: _ctx.preferences.vomiting,
              key: _ctx.preferences.vomiting,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[15] || (_cache[15] = ($event: any) => (_ctx.preferences.vomiting = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.vasovagal_syncope'),
              value: _ctx.preferences.vasovagal_syncope,
              key: _ctx.preferences.vasovagal_syncope,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[16] || (_cache[16] = ($event: any) => (_ctx.preferences.vasovagal_syncope = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.n_drop_ae'),
              value: _ctx.preferences.n_drop_ae_,
              key: _ctx.preferences.n_drop_ae_,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[17] || (_cache[17] = ($event: any) => (_ctx.preferences.n_drop_ae_ = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.n_drop'),
              value: _ctx.preferences.n_drop_,
              key: _ctx.preferences.n_drop_,
              disabledOptions: _ctx.disabledOptions,
              onChanged: _cache[18] || (_cache[18] = ($event: any) => (_ctx.preferences.n_drop_ = $event))
            }, null, 8, ["text", "value", "disabledOptions"])),
            (_openBlock(), _createBlock(_component_YesNoLabel, {
              text: _ctx.$t('PreferencesNoStudies.child_participation'),
              modelValue: _ctx.preferences.child_participation,
              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.preferences.child_participation) = $event)),
              key: _ctx.preferences.child_participation
            }, null, 8, ["text", "modelValue"])),
            (_openBlock(), _createBlock(_component_Textarea, {
              text: _ctx.$t('PreferencesNoStudies.other_preferences'),
              modelValue: _ctx.preferences.other_preferences,
              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.preferences.other_preferences) = $event)),
              key: _ctx.preferences.other_preferences
            }, null, 8, ["text", "modelValue"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.understandable'),
              value: _ctx.satisfactions.understandable,
              key: _ctx.satisfactions.understandable,
              onChanged: _cache[21] || (_cache[21] = ($event: any) => (_ctx.satisfactions.understandable = $event)),
              n: 5
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.useful'),
              value: _ctx.satisfactions.useful,
              key: _ctx.satisfactions.useful,
              onChanged: _cache[22] || (_cache[22] = ($event: any) => (_ctx.satisfactions.useful = $event)),
              n: 5
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.easy_to_use'),
              value: _ctx.satisfactions.easy_to_use,
              key: _ctx.satisfactions.easy_to_use,
              onChanged: _cache[23] || (_cache[23] = ($event: any) => (_ctx.satisfactions.easy_to_use = $event)),
              n: 5
            }, null, 8, ["text", "value"])),
            (_openBlock(), _createBlock(_component_RadioN, {
              text: _ctx.$t('PreferencesNoStudies.participation_importance'),
              value: _ctx.satisfactions.participation_importance,
              key: _ctx.satisfactions.participation_importance,
              onChanged: _cache[24] || (_cache[24] = ($event: any) => (_ctx.satisfactions.participation_importance = $event)),
              n: 5
            }, null, 8, ["text", "value"])),
            _createVNode(_component_Select, {
              text: _ctx.$t('PreferencesNoStudies.pat_over_clin_importance'),
              options: [
                _ctx.$t('PreferencesNoStudies.pat_over_clin_importance_less'),
                _ctx.$t('PreferencesNoStudies.pat_over_clin_importance_little_less'),
                _ctx.$t('PreferencesNoStudies.pat_over_clin_importance_equals'),
                _ctx.$t('PreferencesNoStudies.pat_over_clin_importance_little_more'),
                _ctx.$t('PreferencesNoStudies.pat_over_clin_importance_more')
              ],
              modelValue: _ctx.preferences.pat_over_clin_importance,
              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.preferences.pat_over_clin_importance) = $event))
            }, null, 8, ["text", "options", "modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("button", {
            onClick: _cache[26] || (_cache[26] = ($event: any) => (_ctx.$router.back())),
            type: "button",
            class: "bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          }, _toDisplayString(_ctx.$t('PreferencesNoStudies.Back')), 1),
          _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t('PreferencesNoStudies.Save')), 1)
        ])
      ])
    ], 32)
  ]))
}