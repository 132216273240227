
import Patient from '@/models/Patient'
import App from '@/models/App'
import RadioN from '@/components/RadioN.vue'
import YesNoLabel from '@/components/YesNoLabel.vue'
import Select from '@/components/Select.vue'
import Textarea from '@/components/Textarea.vue'
import Swal from 'sweetalert2'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { mapActions, mapGetters } from 'vuex'

@Options({
  components: {
    RadioN,
    Select,
    Textarea,
    YesNoLabel
  },
  methods: mapActions([
    'downloadPatientPreferences',
    'downloadPatientSatisfactions',
    'updatePreferences',
    'updateSatisfactions'
  ]),
  computed: mapGetters(['patient', 'preferences', 'satisfactions', 'limits'])
})
export default class PreferencesNoStudies extends Vue {
  patient!: Patient
  preferences!: Patient['preferences']
  satisfactions!: Patient['satisfactions']
  limits!: App['limits']
  disabledOptions: Array<number> = []

  // Actions
  updatePreferences!: (payload: {
    id: number
    preferences: Patient['preferences']
  }) => Promise<void>

  updateSatisfactions!: (payload: {
    id: number
    satisfactions: Patient['satisfactions']
  }) => Promise<void>

  downloadPatientPreferences!: (alias: string) => Promise<void>
  downloadPatientSatisfactions!: () => Promise<void>

  async created (): Promise<void> {
    const alias = String(this.$route.params.alias)
    try {
      await this.downloadPatientPreferences(alias)

      await this.downloadPatientSatisfactions()

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = error.response.data.message
      if (message === 'Patient not found.') {
        await Swal.fire({
          icon: 'info',
          title: this.$t('PreferencesNoStudies.DownloadFailedTitle'),
          confirmButtonText: this.$t(
            'PreferencesNoStudies.DownloadFailedConfirmButton'
          )
        })

        this.$router.push({ name: 'Dashboard' })
      } else {
        this.$router.push({ name: 'Logout' })
      }
    }
  }

  async submit (): Promise<void> {
    try {
      await this.updatePreferences({
        id: this.patient.id,
        preferences: this.preferences
      })

      await this.updateSatisfactions({
        id: this.patient.id,
        satisfactions: this.satisfactions
      })

      this.$router.push({
        name: this.$route.meta.next,
        params: { id: this.patient.id }
      })
    } catch (error) {
      this.$router.push({ name: 'Logout' })
    }
  }

  @Watch('preferences', { deep: true })
  onPreferencesChanged (newValue: Patient['preferences']): void {
    const { preferences } = this.limits
    const preferencesLimits = Object.keys(preferences).map((p) => +p)
    const count = {}
    for (const [, value] of Object.entries(newValue)) {
      if (typeof value === 'number' && preferencesLimits.includes(value)) {
        count[value] = count[value] + 1 || 1
      }
    }

    this.disabledOptions = []

    for (const key in count) {
      if (count[key] >= preferences[key]) {
        this.disabledOptions.push(+key)
      }
    }
  }
}
